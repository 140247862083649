.table {
    margin-bottom: initial !important;
}

.draghandle {
    cursor: move;
}

.Table {
    width: 100%;
    margin-top: 15px;
}

.evenRow:last-child,
.oddRow:last-child {
    border-bottom: initial;
}

.headerRow {
    border-bottom: 3px solid #e0e0e0;
    overflow: initial !important;
}

.oddRow {
    /* background-color: #fafafa; */
}

.evenRow:hover,
.oddRow:hover,
.evenRow:active,
.oddRow:active,
.evenRow:focus,
.oddRow:focus {
    background-color: #F9FAFC;
}

.headerColumn {
    font-size: 12px;
    color: #a0a0a0;
}

.actionColumn {
    align-content: left;
}

.exampleColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkboxLabel {
    margin-left: .5rem;
}

.checkboxLabel:first-of-type {
    margin-left: 0;
}

.noRows {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: #bdbdbd;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ReactVirtualized__Table__headerRow {
    text-transform: inherit;
    font-weight: 500;
}

.ReactVirtualized__Table__rowBorder {
    position: absolute;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    left: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
}

.ReactVirtualized__Table__row:last-child > .ReactVirtualized__Table__rowBorder:first-child {
    border-bottom: none;
}
