/* font */
@font-face {
    font-family: 'Manrope';
    src: url('../font/Manrope/Manrope-Medium.ttf');
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('../font/Manrope/Manrope-Medium.ttf');
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('../font/Manrope/Manrope-Bold.ttf');
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('../font/Manrope/Manrope-ExtraBold.ttf');
}

body {
    font-family: "Manrope", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.font-weight-light {
    font-family: "Manrope-Medium";
    font-weight: initial !important;
}

.font-weight-medium {
    font-family: "Manrope-Medium";
    font-weight: initial !important;
}

.font-weight-bold {
    font-family: "Manrope-Bold";
    font-weight: initial !important;
}
