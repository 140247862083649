.fullscreen-minus-nav {
    min-height: calc(100vh - 3rem - 54px);
    max-height: calc(100vh - 3rem - 54px);
}

.desc {
    background: -webkit-linear-gradient(rgba(160, 160, 160, 0.25) 50%, rgba(160, 160, 160) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.asc {
    background: -webkit-linear-gradient(rgb(43, 29, 29) 50%, rgba(160, 160, 160, 0.25) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sort {
    background: rgba(160, 160, 160, 0.25);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.was-validated div.is-invalid>div:nth-child(1) {
    border-color: #dc3545;
    /* padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem) !important; */
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    transition: none !important;
    -webkit-transition: none !important;
}

/* .was-validated div.is-valid>div:nth-child(1) {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem) !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    transition: none !important;
    -webkit-transition: none !important;
} */

.ReactVirtualized__Grid__innerScrollContainer,
.ReactVirtualized__Table__row,
.ReactVirtualized__Table__rowColumn.action-column {
    overflow: visible !important;
}

.ReactVirtualized__Table__row:hover {
    color: #212529;
    cursor: pointer;
}

small + br + * {
    color: black !important;
}

.react-datepicker-popper {
    z-index: 1031;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #DEE2E6;
    padding-right: 1px;
    background-image: initial;
    background-repeat: no-repeat;
    background-position: center right calc(0.4em + 0.1875rem);
    background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);

    display: block;
    /* width: 100%; */
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.6;
    color: #5D636D;
    background-color: #F9FAFC;
    background-clip: padding-box;
    border: 1px solid #DEE2E6;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    color: #5D636D;
    background-color: #F9FAFC;
    border-color: #9ee0ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 183, 255, 0.25);
}

.css-kj6f9i-menu {
    z-index: 3 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    padding-right: 1px;
    background-image: initial;
    background-repeat: initial;
    background-position: initial;
    background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.form-control:disabled {
    color: rgb(170, 170, 170) !important;
    cursor: default;
}

.avatar-image__image img {
    height: 100%;
    transform: translate(-50%, -50%) scale(1.05);
    object-fit: cover;
}

.carousel {
    box-shadow: 0 5px 8px 0px rgba(15,18,26,0.2);
    border-radius: 1.25rem 1.25rem 0 0;
    overflow: hidden;
}

.carousel-control-prev-icon {
    font: normal normal normal 14px/1 FontAwesome;
}

.carousel-control-prev-icon:before {
    content: "";
}

.carousel-control-next-icon {
    font: normal normal normal 14px/1 FontAwesome;
}

.carousel-control-next-icon:before {
    content: "";
}

.carousel-item img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.carousel-item {
    padding-bottom: 56.25%;
}

.carousel .carousel-caption {
    bottom: 0px;
}

.carousel h3 {
    font-size: 1rem;
    bottom: 0;
    color: white;
}

/* .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    overflow-y: hidden !important;
} */

.background-image {
    background-position: center;
    background-size: cover;
    background-color: #bdd4e7;
    background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
}

.zoom {
    transition: all 0.2s ease-in;
}

.zoom:hover {
    transform: scale(1.01);
}

.zoom:focus {
    transform: scale(1.00) !important;
}

.zoom:active {
    transition: all 0.05s ease-out;
    transform: scale(1.00) !important;
}

.clickable {
    cursor: pointer;
}

.btn-outline-facebook {
    color: rgb(24, 119, 242);
    border-color: rgb(24, 119, 242);
}

.btn {
    border-radius: 1.5rem;
}

.layout__sidebar--slim + #layout__split-view > div > div {
    left: 60px !important;
}

.card {
    border: initial;
    box-shadow: 0 5px 8px 0px rgba(15,18,26,0.2);
    border-radius: 8px;
    overflow: hidden;
}

body {
    background-color: rgb(241,241,245);
}

*:focus {
    outline: none;
}

.layout__navbar {
    background: rgb(241, 241, 245);
}

.card-header {
    border-bottom: initial;
    background-color: initial;
}

body {
    /* background-image: url("../assets/ic_background.png");
    background-repeat: repeat-x; */
}
/*
.fullscreen__section--end {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
} */

.fullscreen__section--bottom {
    align-items: flex-end !important;
}
