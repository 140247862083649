@import "./../variables.scss";

.dropzone {
    border: 2px dashed $gray-300;
    padding: 20px;
    text-align: center;
    cursor: pointer;

    &:hover,
    &--active {
        border-color: $primary;
        background-color: rgba($primary, 0.1);
    }
}
