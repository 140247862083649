$color-accent-1: #1167f5;
$color-tertiary: rgba(15, 18, 26, 0.4);

.footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;

    box-shadow: 0 3px 8px 0px rgba(15,18,26,0.2);
    backdrop-filter: blur(4px);

    background-color: rgba(white, 0.85);

    z-index: 1031;
}

#SchTutorProfileDetails {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-bottom: 30px;
    }

    .card-header {
        font-size: 13px;
        font-weight: 800;
        padding-bottom: 0;
    }

    .card {
        .card-header ~ .card-body {
            // padding-top: 8px;
        }
    }

    a.link {
        color: $color-accent-1;
    }

    a.link:hover {
        text-decoration: underline;
    }

    .card-header-tabs {
        margin-right: -1.25rem;;
        margin-left: -1.25rem;
        margin-top: -0.5rem;
    }

    li.nav-item {
        min-width: 120px;
        text-align: center;
    }

    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: transparent;
    }

    .nav-tabs .nav-link:hover, .nav-tabs .nav-link {
        color: $color-tertiary;
        border-color: transparent;
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        border-color: transparent;
        color: initial;
        border-bottom: 2px solid $color-accent-1;
    }

    .hidden {
        display: none;
    }
}

@media (max-width: 1200px) {
    .hidden-xl-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}
