.p-2px {
    padding: 2px !important;
}

.my-2px {
    margin-top: 2px;
    margin-bottom: 2px;
}

.mx-2px {
    margin-left: 2px;
    margin-right: 2px;
}

.mt-2px {
    margin-top: 2px;
}

.mb-2px {
    margin-bottom: 2px;
}

.ml-2px {
    margin-left: 2px;
}

.mr-2px {
    margin-right: 2px;
}


.p-4px {
    padding: 4px !important;
}

.my-4px {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mx-4px {
    margin-left: 4px;
    margin-right: 4px;
}

.mt-4px {
    margin-top: 4px;
}

.mb-4px {
    margin-bottom: 4px;
}

.ml-4px {
    margin-left: 4px;
}

.mr-4px {
    margin-right: 4px;
}


.p-6px {
    padding: 6px !important;
}

.my-6px {
    margin-top: 6px;
    margin-bottom: 6px;
}

.mx-6px {
    margin-left: 6px;
    margin-right: 6px;
}

.mt-6px {
    margin-top: 6px;
}

.mb-6px {
    margin-bottom: 6px;
}

.ml-6px {
    margin-left: 6px;
}

.mr-6px {
    margin-right: 6px;
}


.p-8px {
    padding: 8px !important;
}

.my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mx-8px {
    margin-left: 8px;
    margin-right: 8px;
}

.mt-8px {
    margin-top: 8px;
}

.mb-8px {
    margin-bottom: 8px;
}

.ml-8px {
    margin-left: 8px;
}

.mr-8px {
    margin-right: 8px;
}


.p-10px {
    padding: 10px !important;
}

.my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mx-10px {
    margin-left: 10px;
    margin-right: 10px;
}

.mt-10px {
    margin-top: 10px;
}

.mb-10px {
    margin-bottom: 10px;
}

.ml-10px {
    margin-left: 10px;
}

.mr-10px {
    margin-right: 10px;
}


.p-12px {
    padding: 12px !important;
}

.my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
}

.mx-12px {
    margin-left: 12px;
    margin-right: 12px;
}

.mt-12px {
    margin-top: 12px;
}

.mb-12px {
    margin-bottom: 12px;
}

.ml-12px {
    margin-left: 12px;
}

.mr-12px {
    margin-right: 12px;
}


.p-16px {
    padding: 16px !important;
}

.my-16px {
    margin-top: 16px;
    margin-bottom: 16px;
}

.mx-16px {
    margin-left: 16px;
    margin-right: 16px;
}

.mt-16px {
    margin-top: 16px;
}

.mb-16px {
    margin-bottom: 16px;
}

.ml-16px {
    margin-left: 16px;
}

.mr-16px {
    margin-right: 16px;
}


.p-20px {
    padding: 20px !important;
}

.my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mx-20px {
    margin-left: 20px;
    margin-right: 20px;
}

.mt-20px {
    margin-top: 20px;
}

.mb-20px {
    margin-bottom: 20px;
}

.ml-20px {
    margin-left: 20px;
}

.mr-20px {
    margin-right: 20px;
}

.m-neg-8px {
    margin: -8px;
}


.p-24px {
    padding: 24px !important;
}

.my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
}

.mx-24px {
    margin-left: 24px;
    margin-right: 24px;
}

.mt-24px {
    margin-top: 24px;
}

.mb-24px {
    margin-bottom: 24px;
}

.ml-24px {
    margin-left: 24px;
}

.mr-24px {
    margin-right: 24px;
}

.m-neg-8px {
    margin: -8px;
}