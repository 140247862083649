.photopicker-thumb {
  display: inline-flex;
  position: relative;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 4px;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
}

.photopicker-thumb:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.photopicker-thumb-inner {
  min-width: 0px;
  overflow: hidden;
}
