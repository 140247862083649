/* React Datepicker Overrides */

// Card
.react-datepicker {
	border: solid 1px $gray-400 !important;
	font-family: inherit  !important;
	box-shadow: $box-shadow;
}
.react-datepicker__header {
	background-color: $gray-200 !important;
	border-bottom: solid 0px $gray-400 !important;
}

// Card Header
.react-datepicker__navigation--next {
	border-left-color: $gray-500 !important;
}
.react-datepicker__navigation--next:hover {
	border-left-color: $gray-600 !important;
}
.react-datepicker__navigation--previous {
	border-right-color: $gray-500 !important;
}
.react-datepicker__navigation--previous:hover {
	border-right-color: $gray-600 !important;
}

// Month Header
.react-datepicker__current-month {
	color: $black !important;
	font-weight: 500 !important;
	font-size: 14px !important;
}
.react-datepicker__day-name {
	color: $body-color !important;
}

// Active Day
.react-datepicker__day--selected {
	color: $white !important;
	background-color: $primary !important;
}
// Hover Day
.react-datepicker__day:hover {
	background-color: $primary !important;
	color: $white !important;
}


// Day In Range
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
	background-color: lighten($primary, 15%) !important;
	color: $white !important;
}
.react-datepicker__day--in-range {
	background-color: lighten($primary, 24%) !important;
	color: $white !important;
}

// Day in Selecting Range
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
	background-color: $primary !important;
	color: $white !important;
}

// Triangle Top
[data-placement^="bottom"] .react-datepicker__triangle {
	border-bottom-color: $gray-200 !important;
}
[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: $gray-400 !important;
}

// Triangle Bottom
[data-placement^="top"] .react-datepicker__triangle {
	border-top-color: $gray-400 !important;
}
[data-placement^="top"] .react-datepicker__triangle::before {
	border-top-color: $gray-400 !important;
}