/* React Grid Layout Overrides */
@import "./../variables.scss";

.react-grid-layout {
    margin: 0 (-$grid-gutter-width / 2);

    .react-grid-item.react-grid-placeholder {
        background: none;
        z-index: 2;
        transition-duration: 100ms;
        user-select: none;
        position: relative;
        opacity: 0.7;

        &:after {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0);
            content: " ";
            background: rgba($primary, 0.3);
            border-radius: 4px;
            border: 2px dashed $primary;
            height: calc(100% - #{$grid-gutter-width / 2});
            width: calc(100% - #{$grid-gutter-width});
        }
    }
}

.float-column {
    display: flex;
    flex-direction: column;

    > .card {
        flex: 1 1 auto;
        margin-bottom: $grid-gutter-width / 2;

        + .react-resizable-handle {
            bottom: $grid-gutter-width / 2;
            right: $grid-gutter-width / 2;
        }

        > .card-header {
            cursor: move;
            user-select: none;
            /*
            &:before {
                position: absolute;
                font: normal normal normal 14px/1 FontAwesome;
                content: "\f142";
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);
                color: $text-muted;
            }
            */
        }
    }
}

// Internal Breakpoint System
//=====================================
.float-column {
    // Reset column sizes
    [class^="col-"] {
        flex: 0 0 100%;
        max-width: 100%;
    }

    &--size-lg {
        .col-md-4 {
            flex: 0 0 (100% / 3);
            max-width: (100% / 3);
        }
    }
}