@import 'spacing';
@import 'font';

/* variables */
$color-accent-1: #1167f5;
$color-accent-11-light-2: #6aa0f9;
$color-accent-11-light-3: #d2e2fe;
$color-accent-2: #ffc837;
$color-accent-21-light-2: #ffd158;
$color-accent-21-light-3: #fff0c6;
$color-alternative-1: #00aa6e;
$color-alternative-2: #ff7850;
$color-white: #ffffff;
$color-alert: #ff3535;
$color-primary: #1e1e23;
$color-tertiary: rgba(#0f121a, 0.4);
$color-quaternary: rgba(#0f121a, 0.2);
$color-divider: rgba(#000000, 0.08);
$color-general-bg: #f1f1f5;
$color-overview-bg: #f7f7f7;
$color-search-field-bg: rgba(#767680, 0.12);
$color-text-field-bg: rgba(#767680, 0.06);
$color-overview-bg: #f7f7f7;
$color-search-field-bg: rgba(#767680, 0.12);
$color-text-field-bg: rgba(#767680, 0.06);
$color-chat-bubbles-tutor-1-light: rgba(#a1853b, 0.2);
$color-chat-bubbles-student-light: rgba(#1167f5, 0.2);
$color-label-bg-success: #d7f5eb;
$color-label-bg-fail: #ffe6e1;



/* Kren Style Guide */
.large-title {
    font-size: 34px;
    line-height: 41px;
}

.title-1 {
    font-size: 28px;
    line-height: 34px;
}

.title-2 {
    font-size: 22px;
    line-height: 28px;
}

.title-3 {
    font-size: 20px;
    line-height: 25px;
}

.headline {
    font-size: 17px;
    line-height: 22px;
}

.body {
    font-size: 17px;
    line-height: 22px;
}

.callout {
    font-size: 15px;
    line-height: 20px;
}

.subheadline {
    font-size: 15px;
    line-height: 20px;
}

.footnote {
    font-size: 13px;
    line-height: 18px;
}

.caption-1 {
    font-size: 12px;
    line-height: 16px;
}

.caption-2 {
    font-size: 12px;
    line-height: 16px;
}








/* font */
// @font-face {
//     font-family: 'Montserrat';
//     src: url('../font/Montserrat/Montserrat-Regular.ttf');
// }

// @font-face {
//     font-family: 'Montserrat-Light';
//     src: url('../font/Montserrat/Montserrat-Light.ttf');
// }

// @font-face {
//     font-family: 'Montserrat-Medium';
//     src: url('../font/Montserrat/Montserrat-Medium.ttf');
// }

// @font-face {
//     font-family: 'Montserrat-Bold';
//     src: url('../font/Montserrat/Montserrat-Bold.ttf');
// }

// body {
//     font-family: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// }

// .font-weight-light {
//     font-family: "Montserrat-Light";
//     font-weight: initial !important;
// }

// .font-weight-medium {
//     font-family: "Montserrat-Medium";
//     font-weight: initial !important;
// }

// .font-weight-bold {
//     font-family: "Montserrat-Bold";
//     font-weight: initial !important;
// }








/* btn */
.btn {
    // @extend .font-weight-bold;
    @extend .subheadline;
    border-radius: 22px;
    // padding-top: 10px;
    // padding-bottom: 10px;
}






/* btn-accent */
.btn-accent {
    color: #fff;
    background-color: $color-accent-1;
    border-color: $color-accent-1;
}

.btn-accent:hover {
    color: #fff;
    background-color: darken($color-accent-1, 10%);
    border-color: darken($color-accent-1, 10%);
}

.btn-check:focus+.btn-accent,
.btn-accent:focus {
    color: #fff;
    background-color: darken($color-accent-1, 10%);
    border-color: darken($color-accent-1, 10%);
    box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-check:checked+.btn-accent,
.btn-check:active+.btn-accent,
.btn-accent:active,
.btn-accent.active,
.show>.btn-accent.dropdown-toggle {
    color: #fff;
    background-color: darken($color-accent-1, 10%);
    border-color: darken($color-accent-1, 12.5%);
}

.btn-check:checked+.btn-accent:focus,
.btn-check:active+.btn-accent:focus,
.btn-accent:active:focus,
.btn-accent.active:focus,
.show>.btn-accent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-accent:disabled,
.btn-accent.disabled {
    color: #fff;
    background-color: $color-accent-1;
    border-color: $color-accent-1;
}







.btn-primary {
    color: #fff;
    background-color: $color-accent-1;
    border-color: $color-accent-1;
}

.btn-primary:hover {
    color: #fff;
    background-color: darken($color-accent-1, 10%);
    border-color: darken($color-accent-1, 10%);
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: darken($color-accent-1, 10%);
    border-color: darken($color-accent-1, 10%);
    box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: darken($color-accent-1, 10%);
    border-color: darken($color-accent-1, 12.5%);
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
    color: #fff;
    background-color: $color-accent-1;
    border-color: $color-accent-1;
}









.bg-primary {
    background-color: $color-alternative-1 !important;
}

.bg-secondary {
    background-color: $color-tertiary !important;
}

.bg-tertiary {
    background-color: $color-tertiary !important;
}

.bg-yellow {
    background-color: $color-accent-21-light-3 !important;
}

.bg-alternative-1 {
    background-color: $color-alternative-1 !important;
}

.bg-alternative-2 {
    background-color: $color-alternative-2 !important;
}

.btn-reset {
    border-radius: 0.25rem;
}

.bg-search-field-bg {
    background-color: $color-search-field-bg;
}

.bg-error {
    background-color: $color-alert;
}

.bg-accent-11-light-3 {
    background-color: $color-accent-11-light-3;
}

.bg-accent-21-light-3 {
    background-color: $color-accent-21-light-3;
}

.bg-general-bg {
    background-color: $color-general-bg;
}

.bg-accent-1 {
    background-color: $color-accent-1;
}

.bg-accent-2 {
    background-color: $color-accent-2;
}

.bg-overview-bg {
    background-color: $color-overview-bg;
}





.border-accent-1 {
    border: 1px solid $color-accent-1;
}





.text-primary {
    color: $color-accent-1 !important;
}

.text-accent {
    color: $color-accent-1;
}

.text-accent-2 {
    color: $color-accent-2;
}

.text-dark {
    color: $color-primary;
}

.text-secondary {
    color: rgba($color-primary, 0.6) !important;
}

.text-tertiary {
    color: $color-tertiary;
}

.text-quaternary {
    color: $color-quaternary;
}

.text-general-bg {
    color: $color-general-bg;
}

.text-danger {
    color: $color-alert;
}

.text-alternative-1 {
    color: $color-alternative-1 !important;
}

.text-alternative-2 {
    color: $color-alternative-2 !important;
}

body {
    color: $color-primary;
}









a.badge-outline-primary:hover,
a.badge-outline-primary:focus {
    color: #fff;
    background-color: darken($color-alternative-1, 10%);
}

a.badge-outline-primary:focus,
a.badge-outline-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}





a.badge-outline-primary:hover,
a.badge-outline-primary:focus {
    color: #fff;
    background-color: darken($color-alternative-1, 10%);
}

a.badge-outline-primary:focus,
a.badge-outline-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}









.form-group {
    margin-bottom: 20px;
}

label {
    @extend .caption-1;
    // @extend .font-weight-medium;
    margin-bottom: 4px;
}


.avatar__content {
    border: 2px solid white;
}

.pt-0px {
    padding-top: 0px !important;
}


.container-420px {
    max-width: 420px;

    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 991.98px) {
    .layout__content {
        padding: 0.5rem;
    }
}

.mh-w-footer {
    min-height: calc(100vh - 255px); // top nav has 55px, assume footer is 200px
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: initial;
}

.video-bubbles-container > * {
    // #root
    //     #CnxMeetingDetail.position-fixed.tblr-0px
    //         .position-relative.w-100.h-100
    //             .div-root-1.position-fixed |: right: 329px;
    //                 .participant-video-bubble-container.react-draggable.transform-translate-x
    //                     div
    //                         .makeStyles-container-x.position-relative // container for the profile photo
    //                             .position-absolute.right-12px.bottom-12px.z-index-3 // controls
    //                             img.m-12px
    //
    // To achieve bubble overlapping with one another,
    // with the later bubble on top of the earlier one
    //
    // TODO: This won't work if there are more than 2 participants
    //
    // NOTE: distance between each bubble 21px is 21px; margin is 12px
    margin-left: -45px;
}

body {
    overflow-y: initial !important;
    /* Force sticky header even on smaller device width */
}

.card.card-no-shadow {
    box-shadow: none;
}




@media (max-width: 992px) {
    #layout__split-view > div > div {
        left: 0 !important;
    }
}


@keyframes kf-pulsing {
    0% {
        transform: scale(0);
        background-color: $color-accent-2;
    }
    90% {
        transform: scale(6);
        background-color: transparent;
    }
    100% {
        transform: scale(6);
        background-colorr: transparent;
    }
}

.pulsing {
    animation: kf-pulsing ease-in 3s infinite;
}


@keyframes kf-jumping {
    0% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.jumping {
    animation: kf-jumping ease-in 1s infinite;
}







@keyframes kf-clockwise {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes kf-anticlockwise {
    0% {
        transform: rotate(-360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.clockwise {
    animation: kf-clockwise 3s linear infinite;
}

.anticlockwise {
    animation: kf-anticlockwise 3s linear infinite;
}





.compass {
    border: 6px solid white;
    transition: all 1.5s;
}

.compass-match-success {
    width: 163px !important;
    height: 163px !important;
    transform: rotate(0deg) !important;
}

.compass-match-failed {
    opacity: 0;
}

.compass__container {
    transition: transform 1.5s;
    // background-color: rgba(0, 0, 0, 0.1);

    transform: translate(calc(25% - 71px), 25%) rotate(0deg) !important;
}

.shift-47px-right {
    transform: translateX(47px);
}




.fade-out {
    transition: opacity 1s;
    opacity: 0;
}





.badge-success {
    color: $color-alternative-1;
    background-color: $color-label-bg-success;
}

.slide-up {
    position: relative;
    transition: transform 1s, opacity 1s;
    opacity: 1;
    transform: translateY(-300px);
    bottom: -300px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0;
}


.btn {
    padding-top: 11px;
    padding-bottom: 11px;
}








.badge-accent {
    color: #fff !important;
    background-color: $color-accent-1;
    border: 1px solid $color-accent-1;
}

a.badge-accent:hover,
a.badge-accent:focus {
    color: #fff !important;
    background-color: darken($color-accent-1, 10%);
}

a.badge-accent:focus,
a.badge-accent.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}







.badge-outline-accent {
    color: $color-accent-1 !important;
    background-color: #fff;
    border: 1px solid $color-accent-1 ;
}

a.badge-outline-accent:hover,
a.badge-outline-accent:focus {
    color: #fff !important;
    background-color: $color-accent-1;
}

a.badge-outline-accent:focus,
a.badge-outline-accent.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}


.badge {
    padding: 6px 12px;
    margin: 0;
}

a.fa-star:hover {
    color: $color-accent-2;
}














.btn-outline-alternative-2 {
    color: $color-alternative-2;
    border-color: $color-alternative-2;
}

.btn-outline-alternative-2:hover {
    color: #fff;
    background-color: $color-alternative-2;
    border-color: $color-alternative-2;
}

.btn-outline-alternative-2:focus,
.btn-outline-alternative-2.focus {
    box-shadow: 0 0 0 0.2rem rgba(lighten($color-alternative-2, 10%), 0.5);
}

.btn-outline-alternative-2.disabled,
.btn-outline-alternative-2:disabled {
    color: $color-alternative-2;
    background-color: transparent;
}

.btn-outline-alternative-2:not(:disabled):not(.disabled):active,
.btn-outline-alternative-2:not(:disabled):not(.disabled).active,
.show>.btn-outline-alternative-2.dropdown-toggle {
    color: #fff;
    background-color: $color-alternative-2;
    border-color: $color-alternative-2;
}

.btn-outline-alternative-2:not(:disabled):not(.disabled):active:focus,
.btn-outline-alternative-2:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-alternative-2.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(lighten($color-alternative-2, 10%), 0.5);
}

.transition-all {
    transition: all 0.1s ease-out;
}

.tvpm-fullscreen-side-by-side:first-child {
    position: fixed;
    // overflow: hidden;
    top: 0px;
    bottom: 60px;
    left: 0px;
    width: 50%;

    // border: initial !important;
    // border-radius: initial !important;
    // box-shadow: initial !important;
    // margin: 0 !important;
    z-index: 2;
}

.tvpm-fullscreen-side-by-side {
    position: fixed;
    // overflow: hidden;
    top: 0px;
    bottom: 60px;
    right: 0px;
    width: 50%;

    // border: initial !important;
    // border-radius: initial !important;
    // box-shadow: initial !important;
    // margin: 0 !important;
    z-index: 2;
}

@media (max-width: 576px) {
    .tvpm-fullscreen-side-by-side:first-child {
        top: initial;
        bottom: initial;
        right: initial;
        width: initial;

        top: 0px;
        left: 0px;
        right: 0px;
        height: 50%;
    }

    .tvpm-fullscreen-side-by-side {

        top: initial;
        bottom: initial;
        right: initial;
        width: initial;

        bottom: 60px;
        left: 0px;
        right: 0px;
        height: 50%;
    }

}


.tvpm-fullscreen-single {
    position: fixed;
    // overflow: hidden;
    top: 0px;
    bottom: 60px;
    left: 0px;
    right: 0px;

    // border: initial !important;
    // border-radius: initial !important;
    // box-shadow: initial !important;
    // margin: 0 !important;
    z-index: 2;
}


.tvpm-bubble {
    height: 163px;
    width: 163px;

    border: 6px solid white;

    border-radius: 50%;

    box-shadow: 0 4px 12px 0 rgba(15, 18, 26, 0.2);
    margin: 12px;

    overflow: hidden;

    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.segment-control {
    padding-top: 5px;
    padding-bottom: 5px;
}

.segment-control.active {
    background-color: white;
    box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.04),
        0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.9px;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
}





.btn-outline-primary {
    color: $color-accent-1;
    border-color: $color-accent-1;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: lighten($color-accent-1, 30%);
    border-color: lighten($color-accent-1, 30%);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(lighten($color-accent-1, 10%), 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: $color-accent-1;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: $color-accent-1;
    border-color: $color-accent-1;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(lighten($color-accent-1, 10%), 0.5);
}


.text-strikethrough {
    text-decoration: line-through;
}

.text-underline {
    text-decoration: underline;
}


.btn-outline-warning:hover,
.btn-outline-warning.active:hover {
    color: #fff;
    background-color: $color-accent-2;
    border-color: $color-accent-2;
}

.backdrop-blur {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}





.carousel-control {
    // position: relative;
    padding: 10px;
    width: 35px;
    // height: 15px;
    border-radius: 50%;
    background-color: $color-quaternary;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

button {
    touch-action: manipulation;
}


@media (max-width: 767.98px) {
    .responsive-container {
        padding: 0 15px;
    }

    .sm-px-15px {
        padding-left: 15px;
        padding-right: 15px;
    }
}







.badge-warning {
    color: white;
    background-color: $color-alternative-2;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: white;
    background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.scale-140 {
    transform: scale(1.4);
}

.fullscreen {
    padding-top: 0px;
    padding-bottom: 0px;
}

@media (max-width: 767.98px) {
    .sm-title-2 {
        font-size: 22px;
        line-height: 28px;
    }
}

.close {
    opacity: 1;
    text-shadow: initial;
    background-color: white;
}

.MuiFab-root.MuiFab-lg {
    width: 56px !important;
    height: 56px !important;
}

.screenshot {
    pointer-events: none;
    position: absolute;

    bottom: 63px;
    right: 0px;

    height: 100vh;
    width: 100vw;

    transition: all 0.33s ease-in 0.5s;

    object-fit: cover;

    z-index: 1000;
}

.screenshot-animated {
    bottom: 63px;
    right: 12px;

    height: 100px;
    width: auto;

    opacity: 0;
}

.border-4px {
    border-radius: 4px;
}


.badge-accent-light {
    color: $color-accent-1 !important;
    background-color: rgba($color-accent-1, 0.25);
    // border: 1px solid rgba($color-accent-1, 0.5);
}

a.badge-accent-light:hover,
a.badge-accent-light:focus {
    color: #fff !important;
    background-color: rgba($color-accent-1, 0.25);
}

a.badge-accent-light:focus,
a.badge-accent-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.overflow-initial {
    overflow: initial !important;
}

ul.padding-start {
    padding-inline-start: 20px !important;
}

.wizard-step {
    flex-grow: initial;;
    flex-shrink: initial;
    flex-basis: 100%;
}

.wizard-step {
    display: flex;
    flex-direction: column;
    // width: 100%;
    justify-content: center;
    flex-shrink: 1 !important;
}

.wizard-step__content {
    // max-width: 60px;
    // overflow-wrap: break-word;
    @extend .caption-2;
}

.wizard-horizontal-line {
    position: absolute;

    z-index: -1;
    width: 85%;

    top: 14px;
    left: 30px;
    // right: 30px;

    height: 2px;
    // width: 100%;
    background-color: $color-accent-1;
}

.wizard-step__icon {
    flex: 1 1 16px;
    border: 2px solid $color-tertiary;
    background-color: white;

    font-size: 0.7rem;

    width: 16px;
    height: 16px !important;
}

.wizard-step--complete .wizard-step__icon {
    border: 2px solid $color-accent-1;
    background-color: $color-accent-1;
}

.wizard-step--active .wizard-step__icon {
    border: 2px solid $color-accent-1;
    background-color: white;
}

.wizard-step:not(:last-child):before {
    content: "";

    position: relative;

    z-index: -1;

    top: 9px;
    left: 50px;

    height: 2px;
    width: 102px;

    background-color: $color-quaternary;
}

.wizard-step.wizard-step--complete:not(:last-child):before {
    background-color: $color-accent-1;
}

.wizard-step + .wizard-step {
    margin-left: initial;
}

.wizard-step__icon {
    margin-right: initial;
}

.wizard-step__content {
    margin-top: 5px;
    max-width: 60px;
    white-space: initial;
    text-align: center;
}